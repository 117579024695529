/*
Any specific css classes go here, for cases that we can; handle throgh Tailwind
*/

/* Scrollfix */

html {
  overflow-y: scroll;
  height: 100vh;
  max-height: -webkit-fill-available;
}

.ecommerceAccess {
  background-color: #2fdce1;
  border-radius: 0.5rem;
  --tw-text-opacity: 1;
  border: 1px solid black;
  opacity: 0.95;
  color: #333;
  padding: 1rem /* 16px */;
  text-transform: uppercase;
  display: block;
  width: 100%;
  text-decoration: none;
}

.ecommerceAccess:hover {
  filter: drop-shadow(0rem 0rem 0.5rem #2fdce1);
  color: white;
}

@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@300&display=swap");
/*
* This is for the maps page. Don't remove
* it or bad things will happen...
*/

@media only screen and (max-width: 600px) {
  .ecommerce-maps-toolbar {
    height: 7.5%;
    width: 100%;
  }

  .ecommerce-maps-body {
    height: calc(100vh - 7.5%);
    width: 100%;
  }
}

/*
* For large screens, make the toolbar smaller.
*/
@media only screen and (min-width: 600px) {
  .ecommerce-maps-toolbar {
    height: 4%;
    width: 100%;
  }
  .ecommerce-maps-body {
    height: calc(100vh - 7%);
    width: 100%;
  }
}

.logo {
  display: block;
  width: 4rem /* 64px */;
  height: 2.5rem /* 40px */;
}

/*
* Used to adjust location marker center
*/
.location-marker {
  z-index: 5;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -100%);
  filter: drop-shadow(0 0 0.1rem black);
}

.position-marker {
  z-index: 4;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -100%);
  filter: drop-shadow(0 0 0.1rem black);
}

/* Brief info modal that displays for charging stations */
.EVUPLocationMeta {
  position: relative;
  filter: drop-shadow(0rem -0.05rem 0.15rem black);
  width: fit-content;
  min-width: 10rem;
  padding: 0.2rem;
  transform: translate(-50%, -115%);
  z-index: 8;
  border: 5px solid #2fdce1;
  border-radius: 15px;
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  opacity: 0.95;
  color: #333;
}

.offlineLocationStyle {
  position: relative;
  filter: drop-shadow(0em -0.05em 0.1rem black);
  width: fit-content;
  white-space: nowrap;
  padding: 0.2rem;
  transform: translate(-50%, -150%);
  z-index: 8;
  border: 5px solid #2fdce1;
  border-radius: 15px;
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  opacity: 0.95;
  color: #333;
}

.metaTypeSeperator {
  border-left-width: 2px;
  border-style: solid;
  --tw-border-opacity: 0.4;
  border-color: rgba(3, 3, 3, var(--tw-border-opacity));
}

.metadataFooter {
  color: grey;
  opacity: 0.8;
}

/* Brief info modal that displays for clicking on your current position,
such that user knows what the red dot means if lost */
.posInfo {
  position: relative;
  width: 5rem;
  padding: 0.2rem;
  filter: drop-shadow(0 0 0.2rem black);
  transform: translate(-50%, -150%);
  z-index: 6;
  border: 3px solid #2fdce1;
  border-radius: 5px;
  background-color: white;
  opacity: 0.9;
  color: #333;
}

.charge {
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
  font-weight: 700;
  font-size: 1.875rem /* 30px */;
  line-height: 2.25rem /* 36px */;
  text-align: center;
  margin-left: 0.5rem /* 8px */;
}

.blueDropshadow {
  filter: drop-shadow(0 0 0.1rem #2fdce1);
}

.strongBlueDropshadow {
  filter: drop-shadow(0 0 0.2rem #2fdce1);
}

@media (min-width: 768px) {
  #header {
    width: 180px;
    min-width: 180px;
  }
}

/* Ensure the 'Charge Now' text + logo get proportionally smaller on smaller devices,
Such that it doesnt form 2 lines */
@media only screen and (max-width: 392px) {
  .chargeNow {
    font-size: 1.5rem;
  }
}
/* Fkn iphone 5's man */
@media only screen and (max-width: 350px) {
  .chargeNow {
    font-size: 1.2rem;
  }
  .logo {
    width: 3rem;
    height: 1.875rem;
  }
}

/* Utility */
.topright-over {
  top: -1em;
  right: -1em;
}

/* map z-index fix */
.leaflet-container,
.leaflet-pane,
.leaflet-pane * {
  z-index: 5 !important;
}

.bg-primary {
  background: #333;
}
.bg-secondary {
  background: #2fdce1;
}

.bg-primary-dark {
  background: #444;
}

/* FONTS */

@import url("https://fonts.googleapis.com/css2?family=Comfortaa:wght@300;400;500;600;700&display=swap");

body,
html {
  font-family: "Comfortaa", sans-serif;
}

/* Ecom */
#ecomContainer {
  max-width: 1024px;
}
@media (min-width: 768px) {
  #ecomContainer {
    min-height: 768px;
  }
}

.chargerImg {
  position: relative;
  height: 3rem;
  width: auto;
  right: 0.25rem;
  margin-bottom: 0.25rem;
}

/* animation for icons to spin */
.icon-spin {
  -webkit-animation: icon-spin 2s infinite linear;
  animation: icon-spin 2s infinite linear;
}

@-webkit-keyframes icon-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

@keyframes icon-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

.maps-test-container {
  min-height: --webkit-fill-available;
  height: 100vh;
}

:root {
  --app-height: 100%;
}

html,
body {
  padding: 0;
  margin: 0;
  overflow: hidden;
  width: 100vw;
  height: 100vh;

  @media not all and (hover: hover) {
    height: var(--app-height);
  }
}

/* MODAL RELATED STYLING */

.modal {
  width: 400px;
  height: 100%;
  position: fixed;
  z-index: 20;
  align-content: left;
  background-color: white;
}

.closeButton {
  background-color: #2fdce1;
  width: 3rem;
  height: 3rem;
  padding: 0.75rem;
  z-index: 120;
  align-items: center;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
  border-radius: 0.5rem /* 4px */;
  display: flex;
  position: absolute;
  overflow: hidden;
}

.title {
  width: 100%;
  height: 6rem;
  background-color: #333;
  z-index: 150;
  color: #2fdce1;
  font-family: "Comfortaa", sans-serif;
  border-top: 2px solid #2fdce1;
}

.blueBackdrop {
  background-color: #2fdce1;
}

.plugtitle {
  background-color: #2fdce1;
  z-index: 200;
  padding: 0.35rem;
  font-weight: 1000;
}

.plugtitlebehind {
  background-color: #2fdce1;
  z-index: 150;
}

.dropshadow {
  filter: drop-shadow(0em 0em 0.1rem black);
}

.border-primary {
  border: 2px solid #333;
}

.border-secondary {
  border: 2px solid #2fdce1;
}

.border-small-secondary {
  border: 1px solid #2fdce1;
}

.typetitle {
  color: #2fdce1;
  background-color: #333;
  font-size: 0.8rem /* 14px */;
  text-align: center;
  white-space: nowrap;
  height: 1.5rem;
}

.useNow {
  background-color: #333;
  padding: 0.7rem 1rem;
  border-radius: 1rem;
  margin-top: 1rem;
  color: white;
  font-size: 1.25rem;
  text-shadow: 0 0 0.4rem #2fdce1;
  margin-bottom: 1rem;
  white-space: nowrap;
}

.infoText {
  position: relative;
  margin-bottom: 2rem;
  font-size: 0.85rem;
  list-style-type: square;
  line-height: 1.5;
  overflow-y: hidden;
}

.infoText li {
  margin: 0.35rem 0px;
}

@media only screen and (max-width: 450px) and (min-width: 361px) {
  .useNow {
    padding: 0.9rem;
    font-size: 1rem;
  }
  .typetitle {
    font-size: 0.75rem;
    line-height: 1rem;
    padding: 0.3rem 0.3rem 0.4rem 0.2rem;
    margin-left: 0;
  }
  .infoText {
    font-size: 0.9rem;
  }
  .supportedTypes {
    font-size: 0.7rem;
  }
  .ecommerceModalPrice {
    font-size: 1rem;
  }
}

/* For galaxy fold and iphone SE safari support */
@media only screen and (max-width: 360px) {
  .useNow {
    font-size: 0.9rem;
  }
  .typetitle {
    font-size: 0.85rem;
    padding: 0.3rem 0.3rem 0.5rem 0.1rem;
    line-height: 0.9rem;
  }
  .infoText {
    font-size: 0.85rem;
    margin-right: 0.5rem;
  }
  .portAvailability {
    font-size: 0.8rem;
  }
  .plugtitle {
    font-size: 0.8rem;
  }
  .supportedTypes {
    font-size: 0.6rem;
  }
  .charge {
    margin-left: 0.15rem;
    margin-right: 0.3rem;
    font-size: 1.5rem;
  }
  .ecommerceModalPrice {
    font-size: 0.6rem;
  }
}

.supportedTypes {
  overflow-y: clip;
  font-size: 0.8rem;
}

.plugDivImage {
  width: 5rem /* 80px */;
  margin-right: auto; /* 4px */
  overflow-y: hidden;
}

.portAvailability {
  font-weight: 800;
  margin-top: 0.15rem;
  margin-bottom: 0.25rem /* 4px */;
  white-space: nowrap;
}

.useNow:hover {
  text-shadow: 0 0 0.75rem #2fdce1;
}

.typeSectionBorder {
  border-bottom: 0.1rem dashed rgb(255, 255, 255, 0.6);
}

.sideModal {
  position: absolute;
  right: 0px;
  top: 0px;
  bottom: 0px;
  height: 100%;
  width: 100%;
  max-width: 28rem /* 448px */;
}

.loginModal {
  position: absolute;
  left: 0px;
  top: 0px;
  bottom: 0px;
  height: 100%;
  width: 100%;
  max-width: 20rem;
}

.slideUpDrawer {
  position: absolute;
  right: 0px;
  left: 0px;
  bottom: 0px;
  height: 75%;
  width: 100%;
  align-content: center;
}

.ecommerceModalPrice {
  font-size: 1.2rem;
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 500;
  white-space: nowrap;
}

/* Location.js (Choose Charger Page) Styles */

.metadataCard {
  width: 100%;
  background-color: #333;
  max-width: 400px;
  min-height: 10rem;
  align-items: center;
  border-radius: 0.8rem;
  display: inline-block;
  text-align: left;
}
.cardSection {
  padding-right: 3%;
  padding-left: 3%;
  padding-top: 1rem;
  align-items: center;
  display: inline-block;
  text-align: center;
  width: 95%;
}

.cardSection {
  padding-right: 3%;
  padding-left: 3%;
  padding-top: 1rem;
  align-items: center;
  display: inline-block;
  text-align: center;
  width: 95%;
  margin-left: auto;
}

.chargerListTitle {
  color: #2fdce1;
  letter-spacing: 0.05rem;
  border-right: 1px solid white;
  border-left: 1px solid white;
  height: 50%;
  padding-top: 0.6rem;
  padding-left: 8%;
  padding-right: 8%;
  font-weight: 600;
}

.availability {
  font-size: 1rem /* 18px */;
  line-height: 1.75rem /* 28px */;
  --tw-text-opacity: 0.95;
  color: rgba(250, 250, 250, var(--tw-text-opacity));
  font-size: 1.3rem /* 18px */;
  font-family: "Source Sans Pro", sans-serif;
  letter-spacing: 0.05rem;
}

.chargerPortTitle {
  z-index: 10;
  color: #2fdce1;
  background-color: #222;
  border: 0.5px solid #2fdce1;
  filter: drop-shadow(0 0 0.15rem #2fdce1);
  font-family: "Comfortaa", sans-serif;
  font-size: 1rem;
  font-weight: 500;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
}

.chargerStartButton {
  padding: 0.5rem 0;
  background-color: #2fdce1;
  border-radius: 0.5rem;
  filter: drop-shadow(0 0 0.15rem #2fdce1);
  font-weight: 500;
  text-align: center;
  vertical-align: middle;
  border: 1px solid black;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.chargerStartButton:hover {
  filter: drop-shadow(0 0 0.2rem #2fdce1);
}

.chargerEcomInfo {
  font-size: 0.75rem;
  margin-top: 0.3rem;
  margin-bottom: 0.5rem;
  font-family: "Source Sans Pro", sans-serif;
  color: rgb(235, 226, 226);
}

.chargerInfoText {
  font-family: "Source Sans Pro", sans-serif;
  color: rgb(200, 195, 195);
}

.chargerCardDimensions {
  width: 400px;
  height: 10rem;
}

.chargerOCPPInfo {
  font-size: 0.75rem;
  margin-left: 0.2rem;
  padding: 0.1rem;
  width: auto;
  color: rgb(200, 195, 195);
  font-family: "Source Sans Pro", sans-serif;
  white-space: nowrap;
  max-width: 35%;
}

.brandPrimaryColor {
  color: #2fdce1;
}

.brandSecondaryColor {
  color: #333;
}

.chargerCardDataCategory {
  font-family: "Comfortaa", sans-serif;
  color: #2fdce1;
  font-weight: 700;
  letter-spacing: 0.05rem;
  white-space: nowrap;
}

@media only screen and (max-width: 350px) {
  .chargerListTitle {
    font-size: 0.6rem;
    padding-top: 0.8rem;
  }
  .availability {
    position: relative;
    bottom: 0.1rem;
  }
  .chargerPortTitle {
    font-size: 1rem;
  }
}

.formSectionTitle {
  text-align: center;
  border-radius: 0.25rem /* 4px */;
  padding: 0.25rem /* 4px */;
  background-color: #333;
  color: #2fdce1;
}

.max_w_quarter {
  max-width: 25%;
}

.w_43 {
  width: 43%;
}

.buttonShadow {
  filter: drop-shadow(0 0 0.15rem #2fdce1);
}
.buttonShadow:hover {
  filter: drop-shadow(0 0 0.25rem #2fdce1);
}

.text_xxs {
  font-size: 0.5rem;
}

.mapFooterText {
  font-size: 1rem;
  text-decoration: underline;
}

.width_45 {
  width: 45%;
}

.width_95 {
  width: 95%;
}

.text-orange {
  color: rgb(251 146 60);
}

.text-yellow {
  color: rgb(251 146 60);
}

.text-purple {
  color: rgb(99 102 241);
}

.facebook-background {
  background-color: #3b5998;
}

.source-sans-pro {
  font-family: "Source Sans Pro", sans-serif;
}

/* ensures the increment/decrement arrows always display */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  opacity: 1;
}

.border-color-primary {
  border-color: #333;
}

.border-color-secondary {
  border-color: #2fdce1;
}

.SubscriptionSection {
  width: 100%;
  height: 35rem;
  background-color: #333;
  border: 3px solid black;
  border-radius: 2rem;
}

.top_22 {
  top: 22rem;
}

.width_35_rem {
  width: 35rem;
}

.text-gentle-white {
  color: "#e0e0e0";
}

.comfortaa {
  font-family: "Comfortaa", sans-serif;
}

.hoverShadow:hover {
  filter: drop-shadow(0 0 0.2rem #2fdce1);
}

.toast-message {
border-radius: 200px;
}
.white-glow {
  -webkit-animation: white-glow 1s ease-in-out infinite alternate;
  -moz-animation: white-glow 1s ease-in-out infinite alternate;
  animation: white-glow 1s ease-in-out infinite alternate;
}

@keyframes white-glow {
  from {
    filter: drop-shadow(0 0 0rem white);
  }
  to {
    filter: drop-shadow(0 0 0.2rem white);
  }
}

.white-shadow {
  filter: drop-shadow(0 0 0.2rem black);
}

.right-86 {
  right: 22rem;
}

.bg-dark-primary {
  background-color: #222;
}
